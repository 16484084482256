<template>
  <v-snackbar
    v-model="snackbar"
    color="grey"
    outlined
    :timeout="-1"
    :right="true"
    :absolute="false"
    :style="`bottom: ${index * 65 + 25 * pastNotificationInfoLength}px;`"
    max-width="100%"
  >
    <div class="d-flex" style="font-size: 16px">
      <v-icon color="red " class="pe-2"> mdi-alert-octagon </v-icon>
      <span class="font-weight-bold me-2"
        >Error <span v-if="status">{{ status }}</span
        >:</span
      >
      <span class="font-weight-medium" style="color: grey">{{ text }}</span>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn color="red " text v-bind="attrs" @click="closeToast">
        Cerrar
      </v-btn>
    </template>
    <div v-if="hasInfo">
      <div class="ms-8" v-for="information in info" :key="information">
        <span style="font-size: 15px">{{ information }}</span>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: "Error",
  props: {
    text: String,
    id: Number,
    index: Number,
    status: Number,
    info: Array,
    hasInfo: Boolean,
    pastNotificationInfoLength: Number,
  },
  methods: {
    closeToast() {
      this.snackbar = false;
      this.$emit("closeToast", this.id);
    },
  },
  data() {
    return {
      snackbar: true,
    };
  },
};
</script>

<style></style>
