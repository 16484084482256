<template>
  <v-app id="inspire">
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="ml-4 mr-4 mt-2">
            <div class="display-4">Display 4</div>
            <div class="display-2">Display 2</div>
            <div class="display-1">Display 1</div>

            <h1>H1</h1>
            <h2>H2</h2>
            <h3>H3</h3>
            <h4>H4</h4>
            <div class="subtitle-1">Subtitle 1</div>
            <div class="subtitle-2">Subtitle 2</div>
            <div class="overline">overline</div>
            <div class="d-flex">
              <v-btn class="primary"> 1 </v-btn>
              <v-btn class="secondary ml-2"> 2 </v-btn>
              <v-btn class="accent ml-2"> 3 </v-btn>
              <v-btn class="light ml-2"> 4 </v-btn>
              <v-btn class="gradient-background ml-2 white--text"> 5 </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<style scoped>
.gradient-background {
  background: rgb(37, 2, 106);
  background: linear-gradient(
    326deg,
    rgba(37, 2, 106, 1) 2%,
    rgba(33, 150, 243, 1) 67%
  );
}
</style>
<script>
export default {
  components: {},
  async mounted() {},
  data() {
    return {
      foo: null,
    };
  },
};
</script>
