<template>
  <v-snackbar
    v-model="snackbar"
    color="grey"
    outlined
    :timeout="-1"
    :right="true"
    :absolute="false"
    :style="`bottom: ${index * 65 + 25 * pastNotificationInfoLength}px;`"
    max-width="100%"
  >
    <div class="d-flex" style="font-size: 16px">
      <v-icon color="yellow darken-1" class="pe-2"> mdi-alert </v-icon>
      <span class="font-weight-bold me-2">Alerta :</span>
      <span class="font-weight-medium" style="color: grey">{{ text }}</span>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
        color="yellow darken-1"
        class="font-weight-regular"
        text
        v-bind="attrs"
        @click="closeToast"
      >
        Cerrar
      </v-btn>
    </template>
    <div class="ms-8" v-if="hasInfo">
      <span
        style="font-size: 15px"
        v-for="information in info"
        :key="information"
        >{{ information }}</span
      >
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: "Alert",
  props: {
    text: String,
    id: Number,
    index: Number,
    status: String,
    info: Array,
    hasInfo: Boolean,
    pastNotificationInfoLength: Number,
  },
  methods: {
    closeToast() {
      this.snackbar = false;
      this.$emit("closeToast", this.id);
    },
  },
  data() {
    return {
      snackbar: true,
      timeout: 6000,
    };
  },
};
</script>

<style></style>
