import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00b144",
        secondary: "#25026a",
        dark: "#009624",
        light: "#ffffff",
        accent: "#006c58",
        info: "#2196f3",
        error: "#d50000",
        warning: "#ffb300",
      },
      dark: {
        primary: "#00c853",
        secondary: "#bdbdbd",
        light: "#5efc82",
        accent: "#8c9eff",
        error: "#d50000",
        warning: "#ffb300",
      },
    },
  },
});
