<template>
  <v-app-bar
    :fixed="false"
    :hide-on-scroll="true"
    dense
    app
    color="primary"
    dark
    v-if="this.$route && this.$route.name !== 'Login'"
  >
    <v-toolbar-title>
      <v-btn
        @click="toggleSideBarDrawer"
        color="primary"
        elevation="0"
        class="gradient-background"
      >
        <v-icon> mdi-reorder-horizontal </v-icon>
      </v-btn>
      <v-btn
        class="gradient-background"
        color="primary "
        elevation="0"
        @click="$router.push('/')"
      >
        <v-icon large> mdi-gamma </v-icon>
      </v-btn>
    </v-toolbar-title>

    <v-spacer> </v-spacer>

    <div class="d-flex align-center">
      <v-btn
        small
        class="ml-2"
        elevation="0"
        color="secondary white--text"
        @click="navToCostItems"
      >
        <v-icon> mdi-crop </v-icon>
      </v-btn>
      <v-btn
        small
        class="ml-2"
        elevation="0"
        color="blue darken-4 white--text"
        @click="navToOtherTonCostItems"
      >
        <v-icon> mdi-weight </v-icon>
      </v-btn>
      <v-btn
        small
        class="ml-2"
        elevation="0"
        color="accent white--text"
        disabled
      >
        <v-icon> mdi-content-save-cog </v-icon>
      </v-btn>
      <v-btn small class="ml-2" elevation="0" color="info white--text" disabled>
        <v-icon> mdi-access-point-network </v-icon>
      </v-btn>
      <v-btn
        small
        v-if="userIsAdmin"
        class="ml-2"
        elevation="0"
        color="primary white--text"
        @click="goToUserPanel"
      >
        <v-icon> mdi-account-supervisor</v-icon>
      </v-btn>
      <v-btn
        small
        class="ml-2"
        elevation="0"
        color="primary white--text"
        @click="goToUserProfile"
      >
        <v-icon> mdi-account-circle-outline </v-icon>
      </v-btn>
      <v-btn
        small
        class=""
        elevation="0"
        color="primary white--text"
        @click="sendLogout"
      >
        <v-icon> mdi-logout </v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<style scoped>
.gradient-background:hover {
  background: rgb(37, 2, 106);
  background: linear-gradient(
    326deg,
    rgba(37, 2, 106, 1) 2%,
    rgba(33, 150, 243, 1) 67%
  );
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import { logout } from "../../managrx/AutAndAuthServices";

export default {
  name: "TopBar",
  computed: {
    ...mapGetters(["sideBarDrawer", "userTokenInfo"]),
    userIsAdmin() {
      return this.userTokenInfo?.utype == "admin";
    },
  },
  methods: {
    ...mapActions(["toggleSideBarDrawer"]),
    sendLogout() {
      logout();
      this.$store.dispatch("unsetUserTokenInfo");
      this.$router.go();
    },
    navToCostItems() {
      this.$router.push({ name: "CostItems" });
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
    goToUserProfile() {
      this.$router.push({ name: "UserProfile" });
    },
    goToUserPanel() {
      this.$router.push({ name: "UsersPanel" });
    },
    navToOtherTonCostItems() {
      this.$router.push({ name: "OtherTonCostItems" });
    },
  },
  data: () => ({
    drawer: false,
    items: ["Campana 1 - V1", "Campana 1 - V2"],
  }),
};
</script>
