export function isResponseSuccess(response) {
  if (response.success) {
    return true;
  }
  return false;
}

export function checkNotificationStructure(notification) {
  let correctNotification = false;

  if (notification?.data?.type) {
    switch (notification.data.type) {
      case "error":
        correctNotification = true;
        break;
      case "success":
        correctNotification = true;
        break;
      case "alert":
        correctNotification = true;
        break;
      default:
        correctNotification = false;
    }
  }
  return correctNotification;
}
