<template>
  <v-snackbar
    v-model="snackbar"
    color="grey"
    outlined
    :timeout="-1"
    :right="true"
    :absolute="false"
    :style="`bottom: ${index * 65 + 25 * pastNotificationInfoLength}px;`"
    max-width="100%"
  >
    <div class="d-flex justify-space-around align-center">
      <v-icon color="success " class="pe-2">
        mdi-check-underline-circle-outline
      </v-icon>
      <span class="font-weight-medium" style="color: grey">{{ text }}</span>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn color="success" text v-bind="attrs" @click="closeToast">
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Success",
  props: {
    text: String,
    id: Number,
    index: Number,
    status: Number,
    pastNotificationInfoLength: Number,
  },
  methods: {
    closeToast() {
      this.snackbar = false;
      this.$emit("closeToast", this.id);
    },
  },
  data() {
    return {
      snackbar: true,
    };
  },
};
</script>

<style></style>
