<template>
  <v-app id="inspire">
    <div class="mr-4">
      <div id="innerContent" class="ml-4 mr-4">
        <v-row>
          <v-col>
            <div>
              <span class="float-right mt-2 mr-4 d-flex">
                <v-btn color="accent" x-small>
                  <v-icon color="black" size="15">mdi-amplifier</v-icon>
                  <div class="black--text">Simulaciones</div>
                </v-btn>
              </span>
              <span class="float-right mt-2 mr-4 d-flex">
                <v-btn color="warning" x-small>
                  <v-icon color="black" size="15">mdi-rocket</v-icon>
                  <div class="black--text">Eject</div>
                </v-btn>
              </span>

              <h2 class="overline mt-2 ml-2 grey--text d-flex">
                Property Details
              </h2>
              <h1 class="display-2 mt-1 ml">
                KM 12 La Campostinella, Partido De Santa Cristobal, SJ
              </h1>
              <h5 class="mt-2 ml-2 dark--text d-flex">
                S 34° 38' 3.676'' -58.405934 / W 58° 24' 21.361''
                <v-btn x-small class="grey--text ml-2" elevation="0"
                  >Show Less</v-btn
                >
              </h5>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
