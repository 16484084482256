<template>
  <div>
    <table class="cashflow-table mr-2 ml-2">
      <tr class="pcashflow-table-header">
        <th
          v-for="col in columns"
          :key="col"
          class="pcashflow-table-header-cell"
        >
          {{ col }}
        </th>
      </tr>
      <tbody>
        <tr v-for="(row, i) in rows" :key="i" class="hoverable-row">
          <td
            v-for="value in row"
            :key="value"
            class="pcashflow-table-value-cell"
          >
            <span>
              <span class="value-span">
                {{ value }}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.pcashflow-holder {
  width: 100%;
}

.hoverable-row:hover {
  background-color: rgb(53, 53, 53);
  color: white;
}
.pcashflow-table {
  width: 98%;

  margin-left: auto;
  margin-right: auto;
}

.pcashflow-table-header {
  border-bottom: 1px solid rgb(196, 196, 196);
}
table {
  width: 98%;

  margin-left: auto;
  margin-right: auto;
}

.pcashflow-table-header-cell {
  width: 75px;
  font-size: 18px;
  text-align: left;
  color: rgb(172, 172, 172);
  font-weight: 300;
}

.pcashflow-table-value-cell {
  width: 80px;
  font-size: 25px;
}
</style>

<script>
export default {
  name: "CashflowTable",
  props: { rows: Array, columns: Array, rowKey: String },

  data() {},
};
</script>
