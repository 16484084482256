import axios from "axios";
import { keysToCamel, keysToSnake } from "./objectUtils.js";
import { URL } from "./services.js";

function checkEndpoint(endpoint) {
  if (endpoint[0] !== "/") {
    throw new Error(`{endpoint} does not start with "/"`);
  }
}

function checkOperation(operation) {
  if (!["PUT", "POST"].some((p) => p === operation)) {
    throw new Error("Only PUT and POST are supported operations.");
  }
}

// Don't camel scan be ommited to camelify payloads by default
async function getRequest(endpoint, dontCamel) {
  checkEndpoint(endpoint);
  try {
    let response = await axios(URL + endpoint);

    response = errorHandler(response);

    if (response.success) {
      if (!dontCamel) {
        response.data = keysToCamel(response.data);
      }
    }
    return response;
  } catch (e) {
    if (e.response) {
      console.log("Catching Error on getRequest!");
      let response = e.response;
      response = errorHandler(response);
      return response;
    }
    throw new Error(`${e}`);
  }
}

async function deleteRequest(endpoint) {
  checkEndpoint(endpoint);

  try {
    let response = await axios.delete(URL + endpoint);
    response = errorHandler(response);
    response.data = keysToCamel({ ...response.data });
    return response;
  } catch (e) {
    if (e.response) {
      console.log("Catching Error on deleteRequest!");
      let response = e.response;
      response = errorHandler(response);
      return response;
    }
    throw new Error(`${e}`);
  }
}

async function dataRequest(operation, endpoint, payload, h) {
  let headers = { ...h };

  checkEndpoint(endpoint);
  checkOperation(operation);
  payload = keysToSnake({ ...payload });

  let response = {};

  try {
    console.log(`${operation} ${endpoint}`);
    if (operation === "PUT") {
      response = await axios.put(URL + endpoint, payload, { headers });
    } else if (operation === "POST") {
      response = await axios.post(URL + endpoint, payload, { headers });
    }

    response = errorHandler(response);
    if (response.success) {
      if (typeof response.data === "object") {
        response.data = keysToCamel({ ...response.data });
      }
    }
    return response;
  } catch (e) {
    if (e.response) {
      console.log("Catching Error on dataRequest!");
      console.log(e);

      console.log("Catching Error on postRequest!");
      console.log(e.response);
      let response = e.response;
      response = errorHandler(response);
      return response;
    }
    throw new Error(`${e}`);
  }
}

async function postRequest(endpoint, payload, h) {
  let headers = { ...h };
  try {
    let response = await dataRequest("POST", endpoint, payload, headers);
    return response;
  } catch (e) {
    if (e.response) {
      console.log(e);

      console.log("Catching Error on postRequest!");
      console.log(e.response);
      let response = e.response;
      response = errorHandler(response);
      return response;
    }
    throw new Error(`${e}`);
  }
}

async function putRequest(endpoint, payload, h) {
  let headers = { ...h };
  try {
    let response = await dataRequest("PUT", endpoint, payload, headers);
    return response;
  } catch (e) {
    if (e.response) {
      console.log("Catching Error on putRequest!");
      let response = e.response;
      response = errorHandler(response);
      return response;
    }
    throw new Error(`${e}`);
  }
}

function errorHandler(response) {
  let status = response?.status;
  let params = response?.data?.params;
  let parsedResponse;
  switch (status) {
    case 200:
      parsedResponse = response;
      parsedResponse.success = true;
      break;
    case undefined:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: "Un error desconocido acaba de suceder",
          status: "Unknown",
        },
      };
      break;
    case 400:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: "Solicitud invalida",
          status: status,
          info: [parse400ErrorInformation(response)],
        },
      };
      break;

    case 403:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: `Esta acción no está permitida (${params?.action} ${params?.element} ${params?.id})`,
          status: status,
        },
      };
      break;
    case 404:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: `No hay coincidencias para los datos enviados (${params?.element}: ${params?.id})`,
          status: status,
        },
      };
      break;
    case 405:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: "El metodo usado no es valido",
          status: status,
        },
      };
      break;
    case 409:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: `El valor '${params?.value}' ya esta en uso para '${params?.element}'.`,
          status: status,
        },
      };
      break;
    case 422:
      console.log(response);
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: "Los datos enviados no pudieron ser procesados correctamente",
          status: status,
          info: parse422ErrorInformation(response),
        },
      };
      break;
    case 498:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: `Token de seguridad invalido, porfavor sal y vuelve a ingresar`,
          status: status,
        },
      };
      break;
    case 500:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: "El servidor no pudo procesar la solicitud con éxito",
          status: status,
        },
      };
      break;
    case 503:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: "No es posible conectar con el servidor",
          status: status,
        },
      };
      break;
    default:
      parsedResponse = {
        success: false,
        data: {
          type: "error",
          text: "Un error imprevisto ha ocurrido",
          status: status,
        },
      };
  }
  parsedResponse.completeResponse = response;
  return parsedResponse;
}
function parse400ErrorInformation(response) {
  let information = "";
  let data = response?.data;
  let crossError = data?.["ce_error_code"]?.toString();
  let error400CECodes = Object.keys(CROSS_ENTROPY_ERROR_CODE_TO_TEXT);
  // Check if the 400 error has a custom error code and if that custom error code is a known error
  if (crossError && error400CECodes.includes(crossError)) {
    let errorParams = data?.params;
    let errorParamsKeys = Object.keys(errorParams);
    information = CROSS_ENTROPY_ERROR_CODE_TO_TEXT[data?.["ce_error_code"]];

    // This will check if any params exists on the response and add them to the final error information
    if (errorParamsKeys.length > 0) {
      for (const [index, key] of errorParamsKeys.entries()) {
        if (
          typeof errorParams[key] === "string" ||
          errorParams[key] instanceof String
        ) {
          // parse any posible snake case to text
          errorParams[key] = errorParams[key].replaceAll("_", " ");
        }
        information = information.replace(`{${index}}`, errorParams[key]);
      }
    }
  } else {
    console.log("Unknown 400 ERROR!");
    if (data?.error) {
      console.log("Using response error information");
      // Use response error information
      information = data?.error;
    } else {
      // Fail to found error information on response
      information = "No se pudo obtener más informción acerca de este error.";
    }
  }
  return information;
}

const CROSS_ENTROPY_ERROR_CODE_TO_TEXT = {
  //EmptyDraftEngagement
  100: "Negocio borrador con ID {0} no tiene actividades asociadas todavia.",
  //PermissionsNotFound
  101: "No se encontraron permisos para la acción indicada.",
  //ActivityInUse
  102: "La actividad de cultivo con ID {0} ya esta en uso en el negocio con id {1}",
  //BadUserCredentials
  103: "Los datos ingresados no corresponden a un usuario valido.",
  //BadQQuery
  104: "El parametro {0} no es valido.",
  //BadItemShape
  105: "La categoria {0} tiene una dimensión de {1}, deberia ser {2}.",
  //BadTotalIncome
  106: "El Total Ingresos no puede ser 0.",
  //BadIncomeToCost
  107: "El total de los costos No Autofinanciables no puede ser 0.",
  //BadItemTaxShape
  108: "La categoria de impuesto {0} tiene una dimensión de {1}, deberia ser {2}.",
  //BadMinimumCumBalance
  109: "El valor minimo del Saldo Acumulado esta en 0.",
  //BadPath
  110: "Path not found",
  //AttachHasFailed
  111: "Fallo al hacer el attachment del negocio borrador {0} a la campaña con ID {1}.",
  //BadViewPath
  112: "View Path not found",
  //BadModulePath
  113: "Module Path not found",
  //BadDateRangeValues
  114: "El año de inicio {0} debe ser menor al año de finalización {1}.",
  //NoAssignedHectaresFound
  115: "No se encontraron hectareas asignadas para {0} con ID {1}.",
  //DateOutOfRange
  116: "El rango de años debe estar entre 2000 y 2050.",
  //BadObParam
  118: "El parametro de ordenamiento {0} no es valido.",
  //NoPhysicalHectaresFound
  122: "No se encontraron hectareas fisicas para {0} con ID {1}.",
  //EmptyActivitySlice
  123: "El registro {0} (ID: {1}) se encuentra vacio.",
  //InvalidSliceId
  124: "Las siguientes IDs no corresponden al elemento {0}: {1}",
  //InvalidEstablishmentId
  125: "El ID {0} no corresponse a un establecimiento valido.",
  //InvalidEngagementId
  126: "El ID {0} no corresponse a un negocio valido.",
  //EmptyIdsString
  128: "Ningun ID fue provisto",
  //SomeIdIsNotNumeric
  129: "Algunos IDs provistos no pudieron convertirse a enteros.",
  //UnsuportedTable
  130: "Solo se admite el uso de las tablas cashflows y draft_cashflows para realizar un Bounds Check",
  //BadRangeDates
  131: "Las fechas provistas para el Bounds Check no son fechas validas",
  //DuplicateIds
  132: "Algunos IDs fueron provistos mas de una vez",
  //InvalidCampaignId
  135: "El ID {0} no corresponse a una campaña valida.",
  //SessionTransactionFailed
  136: "La solicitud {0} fallo en completarse, los datos previos a la solicitud fueron restaurados.",
  //ExistingCampaignVersionError
  137: "La campaña abstracta con ID {0} ya tiene una versión {1}",
  //InvalidDirectSliceCostItemId
  140: "El ID {0} no corresponse a un costo directo valido.",
  //InvalidDraftEngagementId
  141: "El ID {0} no corresponse a un negocio borrador valido.",
  //InvalidDraftActivitySliceId
  142: "El ID {0} no corresponse a una actividad valida.",
  // ForeignKeyFails
  143: "La columna {0} usada como ID externa en la tabla {1} no es valida.",
  // CustomIntegrityError
  144: "No se pudo completar la operación, todos los datos fueron restaurados al punto anterior. Detalle: ({0})",
  // CustomOperationalError
  145: "No se cumplen con las restricciones establecidas para la tabla ({0}).",
  // ForeignKeyInUse
  146: "Esa acción no puede llevarse a cabo, la tabla {0} esta usando el dato {1} relacionado a esta tabla.",
  //EmptyCampaign
  147: "La campaña con ID {0} no tiene actividades asociadas todavia.",
  // InvalidActivityId
  148: "El ID {0} no corresponse a una actividad valida.",
};
function parse422ErrorInformation(response) {
  /*   This function will parse automatically generated errors from fast api (422) like this ones

  {"detail":[{"loc":["body","name"],"msg":"field required","type":"value_error.missing"}]}

  {"detail":[{"loc":["path","abstract_campaign_id"],"msg":"value is not a valid integer","type":"type_error.integer"}]} 
*/
  let textArray = [];
  let data = response?.data;
  if (data?.detail) {
    for (const error of data.detail) {
      // Get the location error from loc always 1
      // parse the location from snk case to text
      let errorLocation = error?.loc?.[1]?.replaceAll("_", " ");
      let parsedErrorMessage = `Error en '${errorLocation}', ${error.msg}`;
      textArray.push(parsedErrorMessage);
    }
  }
  return textArray;
}
export { errorHandler, getRequest, postRequest, putRequest, deleteRequest };
