import { getRequest } from "./serverCall";

async function retrieveResourceUtilization(activitySliceId) {
  if (typeof activitySliceId !== "number") {
    return {
      success: false,
      data: {
        type: "alert",
        text: `El valor ${activitySliceId} debe ser numerico`,
      },
    };
  }

  try {
    let response = await getRequest(
      "/module/get/resource_manager?ids_list=" + activitySliceId,
      true
    );
    return response;
  } catch (error) {
    console.log(error);
    console.log(error.response.data);
    return {
      success: false,
      data: {
        type: "error",
        text: "No fue posible mostrar la utilización de recursos",
      },
    };
  }
}

async function retrieveMultiResourceUtilization(sliceIds) {
  try {
    let response = await getRequest(
      "/module/get/resource_manager?ids_list=" + sliceIds.join(","),
      true
    );
    return response;
  } catch (error) {
    return {
      success: false,
      data: {
        type: "error",
        text: "No fue posible mostrar la utilización de recursos",
      },
    };
  }
}
async function retrieveDraftMultiResourceUtilization(sliceIds) {
  try {
    let response = await getRequest(
      "/module/get/draft_resource_manager?ids_list=" + sliceIds.join(","),
      true
    );
    return response;
  } catch (error) {
    return {
      success: false,
      data: {
        type: "error",
        text: "No fue posible mostrar la utilización de recursos",
      },
    };
  }
}

async function retrieveCampaignMultiResourceUtilization(campaignId) {
  try {
    let response = await getRequest(
      "/module/get/campaign_resource_manager?campaign_id=" + campaignId,
      true
    );
    return response;
  } catch (error) {
    return {
      success: false,
      data: {
        type: "error",
        text: "No fue posible mostrar la utilización de recursos",
      },
    };
  }
}
export {
  retrieveCampaignMultiResourceUtilization,
  retrieveResourceUtilization,
  retrieveMultiResourceUtilization,
  retrieveDraftMultiResourceUtilization,
};
