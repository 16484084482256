<template>
  <Login></Login>
</template>

<script>
import Login from "../components/Auth/Login.vue";

export default {
  components: { Login },
  data() {
    return {};
  },
};
</script>
