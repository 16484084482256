let insumos = [
  "Semillas",
  "Herbicidas",
  "Insecticidas",
  "Fertilizantes",
  "Fungicidas",
];
let varios = ["Varios"];

// This will allow to show logs to the user in esp
const AUDIT_LOG_COLUMNS_ENG_TO_ESP = {
  id: "ID",
  engagementId: "ID del Negocio",
  activityId: "ID de la Actividad",
  insuranceCategories: "Categorias de Seguro",
  insuranceRate: "% Seguro",
  insuranceMaterializationDate: "Fecha de Materialización del Seguro",
  assignedHectares: "Hectáreas Asignadas",
  sowDate: "Fecha de siembra",
  harvestDate: "Fecha de cosecha",
  incomeDate: "Fecha de ingreso",
  forwardYield: "Rinde esperado",
  isFixedSharecroppingHandedOver: "Aparcería Fija entrega KG",
  isVariableSharecroppingHandedOver: "Aparcería Variable entrega KG",
  minHarvestCost: "Costo minimo cosecha",
  maxHarvestCost: "Costo maximo cosecha",
  harvestPercentCost: "O pct cosecha",
  rentPercentApplication: "% Arrendamiento",
  freightCostTn: "Costo Flete",
  isActualFreightCostTn: "Flete Estado",
  isActualHarvestCost: "Cosecha Estado",
  fixedSharecroppingPercent: "% Aparcería Fija",
  grainQualityFactor: "Calidad grano",
  saleTax: "Impuesto de venta",
  saleComission: "Comosiones",
  saleHandOver: "Entregador",
  technicalDirection: "Dir. Técnica",
  parentId: "ID padre",
  parentIdTablename: "Nombre del Padre",
  createdAt: "Creado",
  updatedAt: "Actualizado",
  activitySliceId: "Activity Slice ID",
  harvestedHa: "Hectáreas cosechadas",
  production: "Producción",
  date: "Fecha",
  isActual: "Estado",
  floor: "Minimo",
  ceiling: "Maximo",
  percent: "% Porcentaje",
  listedDirectSliceCostItemId: "ID del costo asociado",
  costInputPeriodId: "ID del periodo",
  unitsPerHectare: "Unidades por Ha.",
  percentApplication: "% Aplicación",
  dateUsed: "Fecha de uso",
  datePaid: "Fecha de pago",
  paymentPolicyId: "ID politica de pago",
  interestRateId: "ID intereses",
  otherTonCostId: "ID costo por tonelada",
  priceTn: "Precio por tonelada",
};

// This is use to display the correct logs to the user
const LOG_TABLENAME_TO_PARSED_LOG_ESP = {
  activity_slice: "Configuración Global",
  harvest_event: "Eventos de Cosecha",
  variable_share_crop_schedule: "Aparceria Variable",
  assigned_direct_slice_cost_item: "Costos Directos",
  assigned_other_ton_cost_item: "Costos Por Tonelada",
};

// This help to parse log actions values to real actions in spanish
const LOG_ACTION_TO_PARSED_LOG_ACTION_ESP = {
  0: "Nuevo",
  1: "Editado",
  2: "Eliminado",
};

// This ids are from "Aceites" and "Inoculantes" whose values are no longer supported
const obsoleteCostItemFamilyId = [6, 9];
// This ids has no real use yet
const toSkipCostItemFamilyIds = [11, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22];

let servicios = ["Labranza", "Aplicaciones"];
let seguimientosYControles = ["Seguimiento y Control"];
let Soja1ActivityId = 18;

const cashflowFormulas = {
  // Semillas: "Precio * Hectáreas Sembradas * %App * u/ha",
  // Herbicidas: "Precio * Hectáreas Sembradas * %App * u/ha",
  // Insecticidas: "Precio * Hectáreas Sembradas * %App * u/ha",
  // Fertilizantes: "Precio * Hectáreas Sembradas * %App * u/ha",
  // Fungicidas: "Precio * Hectáreas Sembradas * %App * u/ha",
  // Varios: "Precio * Hectáreas Sembradas * %App * u/ha",
  // Labranza: "Precio * Hectáreas Sembradas * %App * u/ha",
  // Aplicaciones: "Precio * Hectáreas Sembradas * %App * u/ha",
  // "Seguimiento y Control": "Precio * Hectáreas Sembradas * %App",
  // Arrendamientos:
  //   "Precio Actividad De Renta * Hectáreas Sembradas * %App * qqHa * (1/10)",
  // "Aparcería Fija":
  //   "Rinde * (1/10) * Hectáreas Sembradas * %AF * Precio neto de gastos de comercialización",
  // "Aparcería Variable":
  //   "Rinde * (1/10) * Hectáreas Sembradas * %AV * Precio neto de gastos de comercialización",
  // Cosecha:
  //   "Precio de Actividad * Rinde * (1/10) * Hectáreas Sembradas * %Cosecha // Valor de cosecha Minimo/Maximo * Hectáreas Sembradas",
  // Fletes:
  //   "Precio Flete * Rinde * (1/10) * Hectáreas Sembradas * (1 - %AF - %AV)",
  // Acondicionamiento:
  //   "Precio por Tonelada * Rinde * (1/10) * Hectáreas Sembradas * %App  * (1 - %AF - %AV)",
  // "Embolsado Clasico":
  //   "Precio por Tonelada * Rinde * (1/10) * Hectáreas Sembradas * %App  * (1 - %AF - %AV)",
  // "Dirección técnica":
  //   "Rinde * (1/10) * Hectáreas Sembradas * (Precio Actividad * (1 - (Impuesto de Venta + Comisiones de Venta)) - Entregador) * Dirección técnica",
  // "No Autofinanciables":
  //   "Semillas + Herbicidas + Insecticidas + Fertilizantes + Fungicidas + Varios + Labranza + Aplicaciones + Seguimiento y Control + Arrendamientos",
  // Autofinanciables:
  //   "Aparcería Fija + Aparcería Variable + Cosecha + Fletes + Acondicionamiento + Embolsado Clasico + Dirección técnica",
  "Costos Totales": "Autofinanciables + No Autofinanciables",
  "Ingreso Bruto Propio":
    "Precio Actividad * Rinde * (1/10) * Hectáreas Sembradas * (1 - %AF - %AV)",
  "Gastos de Venta":
    "Rinde * (1/10) * Hectáreas Sembradas * (1 - %AF - %AV) * (Precio Actividad * (Impuesto de Venta + Comisiones de Venta) + Entregador)",
  "Aparcerías Netas Equivalentes":
    "Aparcería Fija + Aparcería Variable + Aparcería Bonificada",
  "Resultado Directo": "Total Ingresos - Costos Totales",
  "Total Ingresos":
    "Ingreso Bruto Propio + Aparcerías Netas Equivalentes - Gastos de Venta",
  "IVA de los Egresos No Autofinanciables":
    "IVA de los Egresos No Autofinanciables",
  "IVA de los Egresos Autofinanciables": "IVA de los Egresos Autofinanciables",
  "IVA de los Ingresos": "IVA de los Ingresos",
  "IVA Neto":
    "IVA de los Egresos No Autofinanciables + IVA de los Egresos Autofinanciables + IVA de los Ingresos",
  Saldo: "Resultado Directo + IVA Neto",
  "Saldo Acumulado": "Suma Acumulativa del Saldo",
  PVDT: "%PVDT * Resultado Directo",
  PVP: " %PVP * Resultado Directo",
  "Flujo de Fondos": "Saldo - PVDT // Saldo - PVP",
};
let engagementLevelTotalHaToolTip = "Total / Hectareas Fisicas";
let activityLevelTotalHaToolTip = "Total / Hectáreas Sembradas";
let campaignLevelTotalHaToolTip = "Total / SUMA(Hectáreas Sembradas)";

const ACTUALS_ITEMS = [
  { name: "Proyectado", value: null },
  { name: "Realizado", value: true },
  { name: "No Realizado", value: false },
];

const HARVEST_FREIGHT_ACTUAL_ITEMS = [
  { name: "Proyectado", value: null },
  { name: "Realizado", value: true },
];

const RENT_ACTIVTY_NAME_SOJA = "Soja 1°";
const RENT_ACTIVITY_NAME_MAIZ = "Maíz Tardío";

// const FIXED_QQ_CONTRACT = {
//   name: "Quintales Fijos",
//   icon: "mdi-tractor",
//   value: 0,
//   contractOptions: [],
//   endpoints: [
//     {
//       key: "rentPaymentPlan",
//       endpoint: "/engagement_rent_payment_plan",
//       orderBy: "date",
//     },
//     {
//       key: "rentFixedRows",
//       endpoint: "/engagement_rent_fixation",
//       orderBy: "date",
//     },
//   ],
// };
const FIXED_BONUS_QQ_CONTRACT = {
  name: "Quintales Fijos",
  icon: "mdi-seal-variant",
  value: 0,

  contractOptions: [
    {
      icon: "mdi-tractor",
      name: "USD / Ha",
      key: "isUsdHa",
      value: true,
      endpoint: "/engagement_rent_bonus",
    },
    {
      icon: "mdi-tractor",
      name: "Kg / Ha",
      key: "isUsdHa",
      value: false,
      endpoint: "/engagement_rent_bonus",
    },
  ],
  endpoints: [
    {
      key: "rentPaymentPlan",
      endpoint: "/engagement_rent_payment_plan",
      orderBy: "date",
    },
    {
      key: "rentFixedRows",
      endpoint: "/engagement_rent_fixation",
      orderBy: "date",
    },
    {
      key: "rentBonuses",
      endpoint: "/engagement_rent_bonus",
      orderBy: "bonusDate",
    },
  ],
};
const FIXED_USD_CONTRACT = {
  name: "USD Fijos",
  icon: "mdi-hand-coin-outline",
  value: 1,
  contractOptions: [],
  endpoints: [
    {
      key: "rentPaymentPlan",
      endpoint: "/engagement_rent_usd_payment_plan",
      orderBy: "date",
    },
    {
      key: "rentFixedRows",
      endpoint: "/engagement_rent_usd_fixation",
      orderBy: "date",
    },
  ],
};

// const VALUED_SHARECROP_CONTRACT = {
//   name: "Aparcería Valorizada",
//   icon: "mdi-home-silo-outline  ",
//   id: 3,
//   contractOptions: [],
//   endpoints: [
//     {
//       key: "rentPaymentPlan",
//       endpoint: "/engagement_rent_valued_sharecropping",
//       orderBy: "ceiling",
//     },
//   ],
// };
const CONTRACTS = [
  // FIXED_QQ_CONTRACT,
  FIXED_BONUS_QQ_CONTRACT,
  FIXED_USD_CONTRACT,
  // VALUED_SHARECROP_CONTRACT,
];

// const DRAFT_FIXED_QQ_CONTRACT = {
//   name: "Quintales Fijos",
//   icon: "mdi-tractor",
//   value: 0,
//   contractOptions: [],
//   endpoints: [
//     {
//       key: "rentPaymentPlan",
//       endpoint: "/draft_engagement_rent_payment_plan",
//       orderBy: "date",
//     },
//     {
//       key: "rentFixedRows",
//       endpoint: "/draft_engagement_rent_fixation",
//       orderBy: "date",
//     },
//   ],
// };
const DRAFT_FIXED_BONUS_QQ_CONTRACT = {
  name: "Quintales Fijos",
  icon: "mdi-seal-variant",
  value: 0,
  contractOptions: [
    {
      icon: "mdi-tractor",
      name: "USD / Ha",
      key: "isUsdHa",
      value: true,
      endpoint: "/draft_engagement_rent_bonus",
    },
    {
      icon: "mdi-tractor",
      name: "Kg / Ha",
      key: "isUsdHa",
      value: false,
      endpoint: "/draft_engagement_rent_bonus",
    },
  ],
  endpoints: [
    {
      key: "rentPaymentPlan",
      endpoint: "/draft_engagement_rent_payment_plan",
      orderBy: "date",
    },
    {
      key: "rentFixedRows",
      endpoint: "/draft_engagement_rent_fixation",
      orderBy: "date",
    },
    {
      key: "rentBonuses",
      endpoint: "/draft_engagement_rent_bonus",
      orderBy: "bonusDate",
    },
  ],
};
const DRAFT_FIXED_USD_CONTRACT = {
  name: "USD Fijos",
  icon: "mdi-hand-coin-outline",
  value: 1,
  contractOptions: [],
  endpoints: [
    {
      key: "rentPaymentPlan",
      endpoint: "/draft_engagement_rent_usd_payment_plan",
      orderBy: "date",
    },
    {
      key: "rentFixedRows",
      endpoint: "/draft_engagement_rent_usd_fixation",
      orderBy: "date",
    },
  ],
};

// const DRAFT_VALUED_SHARECROP_CONTRACT = {
//   name: "Aparcería Valorizada",
//   icon: "mdi-home-silo-outline  ",
//   id: 3,
//   contractOptions: [],
//   endpoints: [
//     {
//       key: "rentPaymentPlan",
//       endpoint: "/draft_engagement_rent_valued_sharecropping",
//       orderBy: "ceiling",
//     },
//   ],
// };
const DRAFT_CONTRACTS = [
  // DRAFT_FIXED_QQ_CONTRACT,
  DRAFT_FIXED_BONUS_QQ_CONTRACT,
  DRAFT_FIXED_USD_CONTRACT,
  // DRAFT_VALUED_SHARECROP_CONTRACT,
];
export {
  CONTRACTS,
  // FIXED_QQ_CONTRACT,
  FIXED_BONUS_QQ_CONTRACT,
  FIXED_USD_CONTRACT,
  // VALUED_SHARECROP_CONTRACT,
  RENT_ACTIVITY_NAME_MAIZ,
  RENT_ACTIVTY_NAME_SOJA,
  // DRAFT_FIXED_QQ_CONTRACT,
  DRAFT_FIXED_BONUS_QQ_CONTRACT,
  DRAFT_FIXED_USD_CONTRACT,
  // DRAFT_VALUED_SHARECROP_CONTRACT,
  DRAFT_CONTRACTS,
  ACTUALS_ITEMS,
  HARVEST_FREIGHT_ACTUAL_ITEMS,
  insumos,
  obsoleteCostItemFamilyId,
  toSkipCostItemFamilyIds,
  servicios,
  seguimientosYControles,
  Soja1ActivityId,
  varios,
  cashflowFormulas,
  engagementLevelTotalHaToolTip,
  activityLevelTotalHaToolTip,
  campaignLevelTotalHaToolTip,
  LOG_TABLENAME_TO_PARSED_LOG_ESP,
  LOG_ACTION_TO_PARSED_LOG_ACTION_ESP,
  AUDIT_LOG_COLUMNS_ENG_TO_ESP,
};
