<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Bienvenido</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                ref="userText"
                prepend-icon="mdi-silo"
                name="login"
                label="Login"
                type="text"
                autocomplete="false"
                v-model="username"
              ></v-text-field>
              <v-text-field
                ref="passwordText"
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
              ></v-text-field>
              <v-checkbox
                v-model="keepLogin"
                :label="`Mantener sesión iniciada`"
              ></v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="sendLogin">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  login,
  
} from "../../managrx/AutAndAuthServices";
export default {
  name: "Login",
  props: {
    source: String,
  },
  methods: {
    getTest() {
      localStorage.setItem("toast", 3);
    },
    async sendLogin() {
      let loginPayload = {
        username: this.username,
        password: this.password,
      };
      let result = await login(loginPayload, this.keepLogin);
      if (result.success) {
        this.$store.dispatch("setUserTokenInfo", result.token);
        this.$router.push("/");
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
    },
  },
  data: () => {
    return { username: "", password: "", keepLogin: false, token: "" };
  },
};
</script>

<style></style>
