<template>
  <v-container fluid class="ml-8 mr-4">
    <v-row>
      <v-col>
        <h2 class="overline grey--text">Database Views</h2>

        <v-row>
          <v-col cols="10">
            <div class="d-flex">
              <v-select
                :items="availableViews"
                v-model="selectedView"
                label="Standard"
                class="mr-2"
              ></v-select>
              <v-select
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                v-model="page"
                label="Page Number"
                class="mr-2"
              ></v-select>
              <v-btn
                v-if="!isAvailableViewsLoading"
                class="white--text mt-4"
                elevation="0"
                color="primary"
                @click="executeQuery"
                >Execute</v-btn
              >
              <v-progress-circular
                v-else
                :size="50"
                color="amber"
                indeterminate
              ></v-progress-circular>
              <div></div>
            </div>
            <div class="d-flex flex-column">
              <div class="ml-2 mb-2 caption">
                {{ retrMessage }}
              </div>
              <div
                v-if="isViewObjectLoading"
                class="d-flex justify-center huge-top-margin"
              >
                <v-progress-circular
                  :size="300"
                  color="green"
                  indeterminate
                ></v-progress-circular>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <DenseTableDbView
                :rows="getViewObjectRows"
                :columns="getViewObjectKeys"
              ></DenseTableDbView>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.huge-top-margin {
  margin-top: 200px;
}
</style>
<script>
// import Table from "../components/Table";
import DenseTableDbView from "../components/WIP/DenseTableDbView.vue";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

import { mapGetters } from "vuex";
export default {
  //
  mounted() {
    this.$store.dispatch("getAvailableViews");
  },
  befoerrorestroy() {},
  methods: {
    executeQuery() {
      if (this.selectedView === null) {
        let msg = "Please select a value from the dropdown";
        let notif = {
          success: false,
          data:{
            type: "alert",
            text: msg
          }
        }
        this.$store.dispatch("setNewNotification", notif);
      
      }
      this.$store.dispatch("getDbView", {
        view: this.selectedView, //this.selectedView,
        limit: this.limit,
        page: this.page,
      });
    },
  },
  components: {
    DenseTableDbView,
  },
  computed: {
    ...mapGetters([
      "availableViews",
      "viewObject",
      "isAvailableViewsLoading",
      "isViewObjectLoading",
    ]),
    getViewObjectRows() {
      if (this.viewObject.rows) {
        return this.viewObject.rows;
      } else {
        return [];
      }
    },
    getViewObjectKeys() {
      if (this.viewObject.keys) {
        return this.viewObject.keys.map((i) =>
          toTitleCase(i.replaceAll("_", " "))
        );
      } else {
        return [];
      }
    },
    retrMessage() {
      if (this.viewObject.total === undefined || this.viewObject === {}) {
        return "0 Filas";
      } else {
        return (
          "Pagina " +
          this.page +
          " - " +
          this.limit +
          " Filas de " +
          this.viewObject.total
        );
      }
    },
  },
  data() {
    return {
      // desserts,
      foo: "bar",
      selectedView: null,
      limit: "500",
      page: 1,
    };
  },
};
</script>
