let snakeToEspMap = {
  total_net_income: "total ingreso neto",
  user_lock_datetime: "usuario fecha de bloqueo",
  user_id_simulation_lock: "usuario id bloqueo simulación",
  num_logs: "número de registros",
  log_resource_name: "nombre recurso registro",
  log_origin_cost_item_family_name: "nombre familia ítem costo origen registro",
  log_origin_other_ton_cost_item_name:
    "nombre ítem costo otras toneladas origen registro",
  logs: "registros",
  log_activity_name: "nombre actividad registro",
  log_engagement_name: "nombre compromiso registro",
  log_action_username: "usuario acción registro",
  log_campaign_id: "id campaña registro",
  log_engagement_id: "id compromiso registro",
  log_activity_slice_id: "id  actividad registro",
  old: "antiguo",
  new: "nuevo",
  log_datetime: "fecha y hora registro",
  log_origin_tablename: "nombre tabla origen registro",
  log_row_origin_id: "id fila origen registro",
  log_action: "acción registro",
  log_action_user_id: "id usuario acción registro",
  old_new_value: "valor antiguo nuevo",
  simulation_item_cost_by_hectares: "costo ítem simulación por hectáreas",
  simulation_item_cost: "costo ítem simulación",
  bonus_sharecropping_costs: "costos aparcería bonificada",
  bonus_sharecropping_costs_by_ha: "costos aparcería bonificada por ha",
  bonus_sharecropping_total_percent: "% total aparcería bonificada",
  item_insurance_cost: "costo seguro ítem",
  variable_sharecropping_total_percent: "% total aparcería variable",
  physical_hectares: "hectáreas físicas",
  insurance_materialization_date: "fecha materialización seguro",
  insurance_categories: "categorías seguro",
  insurance_rate: "tasa seguro",
  species_name: "especie",
  bulk_update: "actualización masiva",
  bulk_delete: "eliminación masiva",
  bulk_new: "nuevo masivo",
  species_id: "id especie",
  is_freight_complete: "flete completado",
  is_harvest_complete: "cosecha completada",
  total_actual_count: "items realizados",
  total_scrapped_count: "items no realizados",
  total_planned_count: "items proyectados",
  planned_pct: "% proyectado",
  scrapped_pct: "% no realizado",
  actual_pct: "% realizado",
  is_activity_slice_completed: "actividad completada",
  is_actual_freight_cost_tn: "flete realizado",
  is_actual_harvest_cost: "cosecha realizada",
  is_fixed_sharecropping_handed_over: "aparcería fija entrega kg",
  is_variable_sharecropping_handed_over: "aparcería variable entrega kg",
  forward_yield: "rinde esperado",
  fixed_price: "precio fijo",
  price_ha: "precio por ha",
  production: "producción",
  harvested_ha: "ha cosechadas",
  value: "valor",
  rent_bonus_percent: "porcentaje bonificación alquiler",
  bonus_date: "fecha bonificación",
  yield_threshold: "umbral rinde",
  is_usd_ha: "usd por ha",
  fixed_ton: "tonelada fija",
  contract_type: "tipo contrato",
  is_actual: "realizado",
  parent_id_tablename: "nombre tabla id padre",
  other_ton_cost_total_by_ha: "costo total otras toneladas por ha",
  payment_amount_by_hectare: "monto pago por hectárea",
  total_autofinanciable_costs_by_hectare:
    "total costos autofinanciables por hectárea",
  total_nonautofinanciable_costs_by_hectare:
    "total costos no autofinanciables por hectárea",
  campaign_start_date: "fecha inicio campaña",
  campaign_end_date: "fecha fin campaña",
  variable_participation: "participación variable",
  variable_participation_date: "fecha participación variable",
  created_by: "creado por",
  item_cost_by_hectares: "costo ítem por hectáreas",
  income_date_out_of_bounds: "fecha ingreso fuera de límites",
  sow_date_out_of_bounds: "fecha siembra fuera de límites",
  harvest_date_out_of_bounds: "fecha cosecha fuera de límites",
  insurance_date_out_of_bounds: "fecha seguro fuera de límites",
  assigned_direct_slc_out_of_bounds: "asignado directo slc fuera de límites",
  other_ton_items_out_of_bounds: "otros ítems toneladas fuera de límites",
  draft_activity_slices_cost_bounds:
    "límites costo segmentos actividad borrador",
  abstract_campaign_id: "id campaña abstracta",
  term_5: "término 5",
  used_hectares_ratio: "ratio hectáreas usadas",
  net_income_over_expenses_percent: "porcentaje ingreso neto sobre gastos",
  total_iva_net: "total iva neto",
  iva_net_by_hectares: "iva neto por hectáreas",
  new_draft_engagement_name: "nuevo nombre compromiso borrador",
  draft_name: "nombre borrador",
  is_director: "director",
  director_id: "id director",
  manager_id: "id gerente",
  access_token: "token acceso",
  rent_total_qq: "total alquiler qq",
  password: "contraseña",
  sharecropping_usd_ha: "aparceria total usd/ha",
  new_engagement_id: "nuevo id compromiso",
  error: "error",
  term_1: "término 1",
  term_2: "término 2",
  term_3: "término 3",
  term_4: "término 4",
  harvest_cost: "costo cosecha",
  is_harvest_cost_dependent: "costo cosecha dependiente",
  msg: "mensaje",
  rent_qq_activity: "alquiler qq actividad",
  activity_slice_id: "id  actividad",
  insurance_policy_id: "id póliza seguro",
  sale_hand_over: "entrega venta",
  tax: "impuesto",
  date: "fecha",
  has_region_variants: "tiene variantes región",
  interest_rate_id: "id tasa interés",
  detail: "detalle",
  freight_cost_tn: "costo carga tn",
  direct_slice_cost_item_id: "id ítem costo  directo",
  floor: "suelo",
  ceiling: "techo",
  has_activity_variants: "tiene variantes actividad",
  success: "éxito",
  sale_tax: "impuesto venta",
  payment_policy_id: "id política de pago",
  percent: "porcentaje",
  min_harvest_cost: "costo mínimo cosecha",
  factor: "factor",
  price_tn: "precio tn",
  establishment_id: "id establecimiento",
  rows: "filas",
  abbreviation: "abreviatura",
  created_at: "creado en",
  other_ton_cost_id: "id costo otras toneladas",
  rent_qq_ha: "alquiler qq por ha",
  qq_ha: "qq por ha",
  region_id: "id región",
  end_date: "fecha fin",
  keys: "claves",
  harvest_date: "fecha cosecha",
  price: "precio",
  percent_application: "aplicación porcentaje",
  updated_at: "actualizado en",
  parent_id: "id padre",
  natural_order: "orden natural",
  policy_json: "json política",
  username: "nombre de usuario",
  units_per_hectare: "unidades por hectárea",
  id: "id",
  start_date: "fecha inicio",
  engagement_id: "id compromiso",
  version: "versión",
  assigned_hectares: "hectáreas asignadas",
  max_harvest_cost: "costo máximo cosecha",
  include_autofinanciables: "incluir autofinanciables",
  harvest_percent_cost: "costo porcentaje cosecha",
  name: "nombre",
  cost_item_family_id: "id familia ítem costo",
  campaign_id: "id campaña",
  rate: "tasa",
  loc: "ubicación",
  type: "tipo",
  price_ton: "precio tonelada",
  unit_measure: "unidad medida",
  cost_input_period_id: "id periodo entrada costo",
  premium: "prima",
  date_paid: "fecha pagado",
  materialization_status: "estado materialización",
  listed_direct_slice_cost_item_id: "id ítem costo  directo listado",
  forward_price: "precio forward",
  income_date: "fecha ingreso",
  token: "token",
  autofinanciable: "autofinanciable",
  activity_id: "id actividad",
  technical_direction: "dirección técnica",
  sow_date: "fecha siembra",
  fixed_sharecropping_percent: "% aparcería fija",
  sale_comission: "comisión venta",
  stored_cost_item_group_id: "id grupo ítem costo almacenado",
  date_used: "fecha usado",
  grain_quality_factor: "factor calidad grano",
  hectares: "hectáreas",
  include_harvest: "incluir cosecha",
  total: "total",
  views: "vistas",
  dynamic_yield: "rinde dinámico",
  activity_name: "nombre actividad",
  assigned_direct_slice_cost_item_id: "id ítem costo  directo asignado",
  assigned_other_ton_cost_item_id: "id ítem costo otras toneladas asignado",
  campaign_name: "nombre campaña",
  campaign_version: "versión campaña",
  cost_item_family_name: "nombre familia ítem costo",
  cost_item_natural_order: "orden natural ítem costo",
  direct_slice_cost_item_name: "nombre ítem costo  directo",
  establishment_name: "nombre establecimiento",
  forward_price_activity: "precio forward actividad",
  interest_rate_name: "nombre tasa interés",
  item_cost: "costo ítem",
  item_cost_interest: "costo ítem interés",
  other_ton_cost_item_id: "id ítem costo otras toneladas",
  other_ton_cost_item_name: "nombre ítem costo otras toneladas",
  payment_policy_name: "nombre política de pago",
  period_type: "tipo periodo",
  planned_dollars: "dólares proyectados",
  planned_quintals: "quintales proyectados",
  profit: "beneficio",
  profit_margin: "margen beneficio",
  profit_per_hectare: "beneficio por hectárea",
  region: "región",
  region_name: "nombre región",
  revenues: "ingresos",
  total_costs: "costos totales",
  total_hectares: "hectáreas totales",
  total_profits: "resultado directo",
  total_revenue: "ingresos totales",
  yield_simulation_factor: "factor simulación rinde",
  profits_by_hectare: "beneficios por hectárea",
  rent_percent_application: "% aplicación alquiler",
  engagement_qq_ha: "compromiso qq por ha",
  quintals: "quintales",
  payment_amount: "monto pago",
  payment_date: "fecha pago",
  draft_engagement_id: "id compromiso borrador",
  draft_activity_slice_id: "id  actividad borrador",
  unit_price: "precio unidad",
  activity_price: "precio actividad",
  rent_qq_activity_price: "precio actividad alquiler qq",
  direct_costs: "costos directos",
  autofinanciable_costs: "costos autofinanciables",
  nonautofinanciable_costs: "costos no autofinanciables",
  indifference_qqha: "indiferencia qqha",
  variable_sharecropping_costs: "costo aparcería variable",
  fixed_sharecropping_costs: "costos aparcería fija",
  sales_expenses: "gastos venta",
  sales_expenses_by_hectare: "gastos venta por hectárea",
  direct_costs_by_hectare: "costos directos por hectárea",
  autofinanciable_costs_by_hectare: "costos autofinanciables por hectárea",
  nonautofinanciable_costs_by_hectare:
    "costos no autofinanciables por hectárea",
  total_costs_by_hectare: "costos totales por hectárea",
  total_direct_costs: "costos directos totales",
  total_autofinanciable_costs: "costos autofinanciables totales",
  total_nonautofinanciable_costs: "costos no autofinanciables totales",
  total_sales_expenses: "gastos venta totales",
  modifier: "modificador",
};

let toSnakeMap = {
  fixedTon: "fixed_ton",
  priceTon: "price_ton",
  priceHa: "price_ha",
  newDraftEngagementId: "new_draft_engagement_id",
  parentIdTablename: "parent_id_tablename",
  totalAutofinanciableCostsByHectare: "total_autofinanciable_costs_by_hectare",
  totalNonautofinanciableCostsByHectare:
    "total_nonautofinanciable_costs_by_hectare",
  campaignStartDate: "campaign_start_date",
  campaignEndDate: "campaign_end_date",
  variableParticipation: "variable_participation",
  variableParticipationDate: "variable_participation_date",
  createdBy: "created_by",
  itemCostByHectares: "item_cost_by_hectares",
  draftActivitySlicesCostBounds: "draft_activity_slices_cost_bounds",
  incomeDateOutOfBounds: "income_date_out_of_bounds",
  sowDateOutOfBounds: "sow_date_out_of_bounds",
  harvestDateOutOfBounds: "harvest_date_out_of_bounds",
  insuranceDateOutOfBounds: "insurance_date_out_of_bounds",
  assignedDirectSlcOutOfBounds: "assigned_direct_slc_out_of_bounds",
  otherTonItemsOutOfBounds: "other_ton_items_out_of_bounds",
  abstractCampaignId: "abstract_campaign_id",
  term5: "term_5",
  usedhectaresRatio: "used_hectares_ratio",
  netIncomeOverExpensesPercent: "net_income_over_expenses_percent",
  totalIvaNet: "total_iva_net",
  ivaNetByHectares: "iva_net_by_hectares",
  newDraftEngagementName: "new_draft_engagement_name",
  draftName: "draft_name",
  isDirector: "is_director",
  directorId: "director_id",
  managerId: "manager_id",
  password: "password",
  msg: "msg",
  rentQqActivity: "rent_qq_activity",
  activitySliceId: "activity_slice_id",
  insurancePolicyId: "insurance_policy_id",
  saleHandOver: "sale_hand_over",
  tax: "tax",
  date: "date",
  hasRegionVariants: "has_region_variants",
  interestRateId: "interest_rate_id",
  detail: "detail",
  freightCostTn: "freight_cost_tn",
  directSliceCostItemId: "direct_slice_cost_item_id",
  floor: "floor",
  ceiling: "ceiling",
  hasActivityVariants: "has_activity_variants",
  success: "success",
  saleTax: "sale_tax",
  paymentPolicyId: "payment_policy_id",
  percent: "percent",
  minHarvestCost: "min_harvest_cost",
  factor: "factor",
  priceTn: "price_tn",
  establishmentId: "establishment_id",
  rows: "rows",
  abbreviation: "abbreviation",
  createdAt: "created_at",
  otherTonCostId: "other_ton_cost_id",
  rentQqHa: "rent_qq_ha",
  qqHa: "qq_ha",
  regionId: "region_id",
  endDate: "end_date",
  keys: "keys",
  harvestDate: "harvest_date",
  price: "price",
  percentApplication: "percent_application",
  updatedAt: "updated_at",
  parentId: "parent_id",
  naturalOrder: "natural_order",
  policyJson: "policy_json",
  username: "username",
  unitsPerHectare: "units_per_hectare",
  id: "id",
  startDate: "start_date",
  engagementId: "engagement_id",
  version: "version",
  assignedHectares: "assigned_hectares",
  maxHarvestCost: "max_harvest_cost",
  includeAutofinanciables: "include_autofinanciables",
  harvestPercentCost: "harvest_percent_cost",
  name: "name",
  costItemFamilyId: "cost_item_family_id",
  campaignId: "campaign_id",
  rate: "rate",
  loc: "loc",
  type: "type",
  unitMeasure: "unit_measure",
  costInputPeriodId: "cost_input_period_id",
  premium: "premium",
  datePaid: "date_paid",
  materializationStatus: "materialization_status",
  listedDirectSliceCostItemId: "listed_direct_slice_cost_item_id",
  forwardPrice: "forward_price",
  incomeDate: "income_date",
  token: "token",
  autofinanciable: "autofinanciable",
  activityId: "activity_id",
  technicalDirection: "technical_direction",
  sowDate: "sow_date",
  fixedSharecroppingPercent: "fixed_sharecropping_percent",
  saleComission: "sale_comission",
  storedCostItemGroupId: "stored_cost_item_group_id",
  dateUsed: "date_used",
  grainQualityFactor: "grain_quality_factor",
  hectares: "hectares",
  includeHarvest: "include_harvest",
  total: "total",
  views: "views",
  dynamicYield: "dynamic_yield",
  activityName: "activity_name",
  assignedDirectSliceCostItemId: "assigned_direct_slice_cost_item_id",
  assignedOtherTonCostItemId: "assigned_other_ton_cost_item_id",
  campaignName: "campaign_name",
  campaignVersion: "campaign_version",
  costItemFamilyName: "cost_item_family_name",
  costItemNaturalOrder: "cost_item_natural_order",
  directSliceCostItemName: "direct_slice_cost_item_name",
  establishmentName: "establishment_name",
  forwardPriceActivity: "forward_price_activity",
  interestRateName: "interest_rate_name",
  itemCost: "item_cost",
  itemCostInterest: "item_cost_interest",
  otherTonCostItemId: "other_ton_cost_item_id",
  otherTonCostItemName: "other_ton_cost_item_name",
  paymentPolicyName: "payment_policy_name",
  periodType: "period_type",
  plannedDollars: "planned_dollars",
  plannedQuintals: "planned_quintals",
  profit: "profit",
  profitMargin: "profit_margin",
  profitPerHectare: "profit_per_hectare",
  profitsByHectare: "profits_by_hectare",
  region: "region",
  regionName: "region_name",
  revenues: "revenues",
  totalCosts: "total_costs",
  totalHectares: "total_hectares",
  totalProfits: "total_profits",
  totalRevenue: "total_revenue",
  yieldSimulationFactor: "yield_simulation_factor",
  rentTotalQQ: "rent_total_qq",
  rentPercentApplication: "rent_percent_application",
  engagementQqHa: "engagement_qq_ha",
  quintals: "quintals",
  paymentAmount: "payment_amount",
  paymentDate: "payment_date",
  draftEngagementId: "draft_engagement_id",
  draftActivitySliceId: "draft_activity_slice_id",
  unitPrice: "unit_price",
  activityPrice: "activity_price",
  rentQqActivityPrice: "rent_qq_activity_price",
  directCosts: "direct_costs",
  isActual: "is_actual",
  autofinanciableCosts: "autofinanciable_costs",
  nonautofinanciableCosts: "nonautofinanciable_costs",
  indifferenceQqha: "indifference_qqha",
  variableSharecroppingCosts: "variable_sharecropping_costs",
  fixedSharecroppingCosts: "fixed_sharecropping_costs",
  salesExpenses: "sales_expenses",
  profitPercent: "profit_percent",
  tdmxl: "tdmxl",
  totalSalesExpenses: "total_sales_expenses",
  totalDirectCosts: "total_direct_costs",
  totalAutofinanciableCosts: "total_autofinanciable_costs",
  totalNonautofinanciableCosts: "total_nonautofinanciable_costs",
  paymentAmountByHectare: "payment_amount_by_hectare",
  contractType: "contract_type",
  isUsdHa: "is_usd_ha",
  yieldThreshold: "yield_threshold",
  bonusDate: "bonus_date",
  rentBonusPercent: "rent_bonus_percent",
  value: "value",
  production: "production",
  harvestedHa: "harvested_ha",
  fixedPrice: "fixed_price",
  forwardYield: "forward_yield",
  isFixedSharecroppingHandedOver: "is_fixed_sharecropping_handed_over",
  isVariableSharecroppingHandedOver: "is_variable_sharecropping_handed_over",
  isActualFreightCostTn: "is_actual_freight_cost_tn",
  isActualHarvestCost: "is_actual_harvest_cost",
  isFreightComplete: "is_freight_complete",
  isHarvestComplete: "is_harvest_complete",
  totalActualCount: "total_actual_count",
  totalScrappedCount: "total_scrapped_count",
  totalPlannedCount: "total_planned_count",
  plannedPct: "planned_pct",
  scrappedPct: "scrapped_pct",
  actualPct: "actual_pct",
  isActivitySliceCompleted: "is_activity_slice_completed",
  speciesId: "species_id",
  modifier: "modifier",
  bulkUpdate: "bulk_update",
  bulkDelete: "bulk_delete",
  bulkNew: "bulk_new",
  speciesName: "species_name",
  insuranceCategories: "insurance_categories",
  insuranceRate: "insurance_rate",
  physicalHectares: "physical_hectares",
  insuranceMaterializationDate: "insurance_materialization_date",
  variableSharecroppingTotalPercent: "variable_sharecropping_total_percent",
  itemInsuranceCost: "item_insurance_cost",
  bonusSharecroppingCosts: "bonus_sharecropping_costs",
  bonusSharecroppingCostsByHa: "bonus_sharecropping_costs_by_ha",
  bonusSharecroppingTotalPercent: "bonus_sharecropping_total_percent",
  simulationItemCost: "simulation_item_cost",
  simulationItemCostByHectares: "simulation_item_cost_by_hectares",
  logDatetime: "log_datetime",
  logOriginTablename: "log_origin_tablename",
  logRowOriginId: "log_row_origin_id",
  logAction: "log_action",
  logActionUserId: "log_action_user_id",
  oldNewValue: "old_new_value",
  new: "new",
  old: "old",
  logActivitySliceId: "log_activity_slice_id",
  logCampaignId: "log_campaign_id",
  logEngagementId: "log_engagement_id",
  logEngagementName: "log_engagement_name",
  logActionUsername: "log_action_username",
  logActivityName: "log_activity_name",
  logOriginCostItemFamilyName: "log_origin_cost_item_family_name",
  logOriginOtherTonCostItemName: "log_origin_other_ton_cost_item_name",
  logResourceName: "log_resource_name",
  logs: "logs",
  numLogs: "num_logs",
  userIdSimulationLock: "user_id_simulation_lock",
  userLockDatetime: "user_lock_datetime",
  totalNetIncome: "total_net_income",
};

let toCamelMap = {
  total_net_income: "totalNetIncome",
  user_lock_datetime: "userLockDatetime",
  user_id_simulation_lock: "userIdSimulationLock",
  num_logs: "numLogs",
  log_resource_name: "logResourceName",
  log_origin_cost_item_family_name: "logOriginCostItemFamilyName",
  log_origin_other_ton_cost_item_name: "logOriginOtherTonCostItemName",
  logs: "logs",
  log_activity_name: "logActivityName",
  log_engagement_name: "logEngagementName",
  log_action_username: "logActionUsername",
  log_campaign_id: "logCampaignId",
  log_engagement_id: "logEngagementId",
  log_activity_slice_id: "logActivitySliceId",
  old: "old",
  new: "new",
  log_datetime: "logDatetime",
  log_origin_tablename: "logOriginTablename",
  log_row_origin_id: "logRowOriginId",
  log_action: "logAction",
  log_action_user_id: "logActionUserId",
  old_new_value: "oldNewValue",
  simulation_item_cost_by_hectares: "simulationItemCostByHectares",
  simulation_item_cost: "simulationItemCost",
  bonus_sharecropping_costs: "bonusSharecroppingCosts",
  bonus_sharecropping_costs_by_ha: "bonusSharecroppingCostsByHa",
  bonus_sharecropping_total_percent: "bonusSharecroppingTotalPercent",
  item_insurance_cost: "itemInsuranceCost",
  variable_sharecropping_total_percent: "variableSharecroppingTotalPercent",
  physical_hectares: "physicalHectares",
  insurance_materialization_date: "insuranceMaterializationDate",
  insurance_categories: "insuranceCategories",
  insurance_rate: "insuranceRate",
  species_name: "speciesName",
  bulk_update: "bulkUpdate",
  bulk_delete: "bulkDelete",
  bulk_new: "bulkNew",
  species_id: "speciesId",
  is_freight_complete: "isFreightComplete",
  is_harvest_complete: "isHarvestComplete",
  total_actual_count: "totalActualCount",
  total_scrapped_count: "totalScrappedCount",
  total_planned_count: "totalPlannedCount",
  planned_pct: "plannedPct",
  scrapped_pct: "scrappedPct",
  actual_pct: "actualPct",
  is_activity_slice_completed: "isActivitySliceCompleted",
  is_actual_freight_cost_tn: "isActualFreightCostTn",
  is_actual_harvest_cost: "isActualHarvestCost",
  is_fixed_sharecropping_handed_over: "isFixedSharecroppingHandedOver",
  is_variable_sharecropping_handed_over: "isVariableSharecroppingHandedOver",
  forward_yield: "forwardYield",
  fixed_price: "fixedPrice",
  price_ha: "priceHa",
  production: "production",
  harvested_ha: "harvestedHa",
  value: "value",
  rent_bonus_percent: "rentBonusPercent",
  bonus_date: "bonusDate",
  yield_threshold: "yieldThreshold",
  is_usd_ha: "isUsdHa",
  fixed_ton: "fixedTon",
  contract_type: "contractType",
  is_actual: "isActual",
  parent_id_tablename: "parentIdTablename",
  other_ton_cost_total_by_ha: "otherTonCostTotalByHa",
  payment_amount_by_hectare: "paymentAmountByHectare",
  total_autofinanciable_costs_by_hectare: "totalAutofinanciableCostsByHectare",
  total_nonautofinanciable_costs_by_hectare:
    "totalNonautofinanciableCostsByHectare",
  campaign_start_date: "campaignStartDate",
  campaign_end_date: "campaignEndDate",
  variable_participation: "variableParticipation",
  variable_participation_date: "variableParticipationDate",
  created_by: "createdBy",
  item_cost_by_hectares: "itemCostByHectares",
  income_date_out_of_bounds: "incomeDateOutOfBounds",
  sow_date_out_of_bounds: "sowDateOutOfBounds",
  harvest_date_out_of_bounds: "harvestDateOutOfBounds",
  insurance_date_out_of_bounds: "insuranceDateOutOfBounds",
  assigned_direct_slc_out_of_bounds: "assignedDirectSlcOutOfBounds",
  other_ton_items_out_of_bounds: "otherTonItemsOutOfBounds",
  draft_activity_slices_cost_bounds: "draftActivitySlicesCostBounds",
  abstract_campaign_id: "abstractCampaignId",
  term_5: "term5",
  used_hectares_ratio: "usedhectaresRatio",
  net_income_over_expenses_percent: "netIncomeOverExpensesPercent",
  total_iva_net: "totalIvaNet",
  iva_net_by_hectares: "ivaNetByHectares",
  new_draft_engagement_name: "newDraftEngagementName",
  draft_name: "draftName",
  is_director: "isDirector",
  director_id: "directorId",
  manager_id: "managerId",
  access_token: "accessToken",
  rent_total_qq: "rentTotalQQ",
  password: "password",
  sharecropping_usd_ha: "sharecroppingUsdHa",
  new_engagement_id: "newEngagementId",
  error: "error",
  term_1: "term1",
  term_2: "term2",
  term_3: "term3",
  term_4: "term4",
  harvest_cost: "harvestCost",
  is_harvest_cost_dependent: "isHarvestCostDependent",
  msg: "msg",
  rent_qq_activity: "rentQqActivity",
  activity_slice_id: "activitySliceId",
  insurance_policy_id: "insurancePolicyId",
  sale_hand_over: "saleHandOver",
  tax: "tax",
  date: "date",
  has_region_variants: "hasRegionVariants",
  interest_rate_id: "interestRateId",
  detail: "detail",
  freight_cost_tn: "freightCostTn",
  direct_slice_cost_item_id: "directSliceCostItemId",
  floor: "floor",
  ceiling: "ceiling",
  has_activity_variants: "hasActivityVariants",
  success: "success",
  sale_tax: "saleTax",
  payment_policy_id: "paymentPolicyId",
  percent: "percent",
  min_harvest_cost: "minHarvestCost",
  factor: "factor",
  price_tn: "priceTn",
  establishment_id: "establishmentId",
  rows: "rows",
  abbreviation: "abbreviation",
  created_at: "createdAt",
  other_ton_cost_id: "otherTonCostId",
  rent_qq_ha: "rentQqHa",
  qq_ha: "qqHa",
  region_id: "regionId",
  end_date: "endDate",
  keys: "keys",
  harvest_date: "harvestDate",
  price: "price",
  percent_application: "percentApplication",
  updated_at: "updatedAt",
  parent_id: "parentId",
  natural_order: "naturalOrder",
  policy_json: "policyJson",
  username: "username",
  units_per_hectare: "unitsPerHectare",
  id: "id",
  start_date: "startDate",
  engagement_id: "engagementId",
  version: "version",
  assigned_hectares: "assignedHectares",
  max_harvest_cost: "maxHarvestCost",
  include_autofinanciables: "includeAutofinanciables",
  harvest_percent_cost: "harvestPercentCost",
  name: "name",
  cost_item_family_id: "costItemFamilyId",
  campaign_id: "campaignId",
  rate: "rate",
  loc: "loc",
  type: "type",
  price_ton: "priceTon",
  unit_measure: "unitMeasure",
  cost_input_period_id: "costInputPeriodId",
  premium: "premium",
  date_paid: "datePaid",
  materialization_status: "materializationStatus",
  listed_direct_slice_cost_item_id: "listedDirectSliceCostItemId",
  forward_price: "forwardPrice",
  income_date: "incomeDate",
  token: "token",
  autofinanciable: "autofinanciable",
  activity_id: "activityId",
  technical_direction: "technicalDirection",
  sow_date: "sowDate",
  fixed_sharecropping_percent: "fixedSharecroppingPercent",
  sale_comission: "saleComission",
  stored_cost_item_group_id: "storedCostItemGroupId",
  date_used: "dateUsed",
  grain_quality_factor: "grainQualityFactor",
  hectares: "hectares",
  include_harvest: "includeHarvest",
  total: "total",
  views: "views",
  dynamic_yield: "dynamicYield",
  activity_name: "activityName",
  assigned_direct_slice_cost_item_id: "assignedDirectSliceCostItemId",
  assigned_other_ton_cost_item_id: "assignedOtherTonCostItemId",
  campaign_name: "campaignName",
  campaign_version: "campaignVersion",
  cost_item_family_name: "costItemFamilyName",
  cost_item_natural_order: "costItemNaturalOrder",
  direct_slice_cost_item_name: "directSliceCostItemName",
  establishment_name: "establishmentName",
  forward_price_activity: "forwardPriceActivity",
  interest_rate_name: "interestRateName",
  item_cost: "itemCost",
  item_cost_interest: "itemCostInterest",
  other_ton_cost_item_id: "otherTonCostItemId",
  other_ton_cost_item_name: "otherTonCostItemName",
  payment_policy_name: "paymentPolicyName",
  period_type: "periodType",
  planned_dollars: "plannedDollars",
  planned_quintals: "plannedQuintals",
  profit: "profit",
  profit_margin: "profitMargin",
  profit_per_hectare: "profitPerHectare",
  region: "region",
  region_name: "regionName",
  revenues: "revenues",
  total_costs: "totalCosts",
  total_hectares: "totalHectares",
  total_profits: "totalProfits",
  total_revenue: "totalRevenue",
  yield_simulation_factor: "yieldSimulationFactor",
  profits_by_hectare: "profitsByHectare",
  rent_percent_application: "rentPercentApplication",
  engagement_qq_ha: "engagementQqHa",
  quintals: "quintals",
  payment_amount: "paymentAmount",
  payment_date: "paymentDate",
  draft_engagement_id: "draftEngagementId",
  draft_activity_slice_id: "draftActivitySliceId",
  unit_price: "unitPrice",
  activity_price: "activityPrice",
  rent_qq_activity_price: "rentQqActivityPrice",
  direct_costs: "directCosts",
  autofinanciable_costs: "autofinanciableCosts",
  nonautofinanciable_costs: "nonautofinanciableCosts",
  indifference_qqha: "indifferenceQqha",
  variable_sharecropping_costs: "variableSharecroppingCosts",
  fixed_sharecropping_costs: "fixedSharecroppingCosts",
  sales_expenses: "salesExpenses",
  sales_expenses_by_hectare: "salesExpensesByHectare",
  direct_costs_by_hectare: "directCostsByHectare",
  autofinanciable_costs_by_hectare: "autofinanciableCostsByHectare",
  nonautofinanciable_costs_by_hectare: "nonautofinanciableCostsByHectare",
  total_costs_by_hectare: "totalCostsByHectare",
  total_direct_costs: "totalDirectCosts",
  total_autofinanciable_costs: "totalAutofinanciableCosts",
  total_nonautofinanciable_costs: "totalNonautofinanciableCosts",
  total_sales_expenses: "totalSalesExpenses",
  modifier: "modifier",
};

const isObject = function (obj) {
  return (
    obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function"
  );
};

const keysToCamel = function (obj) {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      let newKey = toCamelMap[k];
      if (!newKey) {
        throw new Error(`${k} does not exist in toCamelMap`);
      }

      n[newKey] = keysToCamel(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return keysToCamel(i);
    });
  }

  return obj;
};

const keysToSnake = function (obj) {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      let newKey = toSnakeMap[k];
      if (!newKey) {
        throw new Error(`${k} does not exist in toSnakeMap`);
      }
      n[newKey] = keysToSnake(obj[k]);
    });
    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return keysToSnake(i);
    });
  }

  return obj;
};

export { keysToCamel, keysToSnake, toSnakeMap, toCamelMap, snakeToEspMap };
