const FORM_ERRORS_ESP = {
  blank: "El campo no puede estar vacio.",
  maxLength: "El maximo de caracteres es ",
  minLength: "El minimo de caracteres es ",
  maxValue: "El valor maximo aceptado es",
  minValue: "El valor minimo aceptado es ",
  fieldType: "El campo debe contener ",
  spaces: "El campo NO debe contener espacios en blanco.",
};
const FIELD_TYPE_TO_ESP = {
  number: "Números",
  string: "Caracteres",
};
const FIELD_RULES_VALIDATION = {
  optional: "boolean",
  maxValue: "number",
  minValue: "number",
  maxLength: "number",
  minLength: "number",
  fieldType: ["number", "string"],
  noSpaces: "boolean",
  cantBeBlank: "boolean",
};
const reWhiteSpace = /\s/;
export function sortArrayOfObjectsByAlphabet(
  arrayToSort,
  keyToSort,
  descending = false
) {
  /* This is a procedure, will affect permantly the given array */
  try {
    if (
      arrayToSort.length == 0 ||
      typeof arrayToSort?.[0]?.[keyToSort] != "string"
    ) {
      throw new Error("Not a valid array to sort");
    }
    // sortDirection = 1 -> ascending order;
    // sortDirection = -1 -> descending order;
    let sortDirection = 1;
    if (descending) {
      sortDirection = -1;
    }
    arrayToSort.sort(
      (a, b) =>
        sortDirection *
        a?.[keyToSort].toLowerCase().localeCompare(b?.[keyToSort].toLowerCase())
    );
  } catch (e) {
    console.log("Sort Array Objects failed");
    console.log(e);
    throw new Error("Sort Array Objects failed");
  }
}
export function validateForm(field, fieldRules, fieldOptional) {
  let detail = [];
  try {
    for (const ruleKey of Object.keys(fieldRules)) {
      if (!FIELD_RULES_VALIDATION?.[ruleKey]) {
        throw new Error("Invalid rule on Field Rules");
      }
      if (ruleKey == "fieldType") {
        if (
          !FIELD_RULES_VALIDATION?.[ruleKey].includes(
            typeof fieldRules?.[ruleKey]
          )
        ) {
          throw new Error("Invalid value type on Field Rules");
        }
      } else if (
        FIELD_RULES_VALIDATION?.[ruleKey] !== typeof fieldRules?.[ruleKey]
      ) {
        throw new Error("Invalid value type on Field Rules");
      }
    }
  } catch (e) {
    console.log("Field validation fail");
    console.log(e);
    throw new Error("Field validation fail");
  }

  if (field == "" && fieldOptional != undefined) {
    return detail;
  }
  if (fieldRules?.maxValue != undefined) {
    detail.push(
      field > fieldRules?.maxValue
        ? FORM_ERRORS_ESP.maxValue + ` ${fieldRules?.maxValue}`
        : true
    );
  }
  if (fieldRules?.minValue != undefined) {
    detail.push(
      field < fieldRules?.minValue
        ? FORM_ERRORS_ESP.minValue + ` ${fieldRules?.minValue}`
        : true
    );
  }
  if (fieldRules?.maxLength != undefined) {
    detail.push(
      field.length > fieldRules?.maxLength ? true : FORM_ERRORS_ESP.maxLength
    );
  }
  if (fieldRules?.minLength != undefined) {
    detail.push(
      field.length > fieldRules?.minLength ? true : FORM_ERRORS_ESP.minLength
    );
  }
  if (fieldRules?.fieldType != undefined) {
    detail.push(
      typeof field != fieldRules?.fieldType
        ? FORM_ERRORS_ESP.fieldType + FIELD_TYPE_TO_ESP[fieldRules?.fieldType]
        : true
    );
  }
  if (fieldRules?.noSpaces != undefined) {
    detail.push(!reWhiteSpace.test(field) ? true : FORM_ERRORS_ESP.spaces);
  }
  if (fieldRules?.cantBeBlank != undefined) {
    detail.push(field !== "" ? true : FORM_ERRORS_ESP.blank);
  }
  return detail;
}

export function roundToX(num, X) {
  return +(Math.round(num + "e+" + X) + "e-" + X);
}

export function addOneMonth(date, deltaDays) {
  let nextMonth = date.getMonth() + 1;
  if (nextMonth == 12) {
    nextMonth = 0;
  }
  date.setMonth(date.getMonth() + 1);
  // Add missing extra days
  date.setDate(date.getDate() + deltaDays);
  // Now delta is 0
  deltaDays = 0;

  // Check overflows
  if (date.getMonth() > nextMonth) {
    // This will remove the overflow days and go to the last day of the previous month
    console.log("date overflow up");
    deltaDays = date.getDate();
    date.setDate(date.getDate() - date.getDate());
  }
  if (date.getMonth() < nextMonth) {
    // This will add the missing days and go to the first day of the next month
    console.log("date overflow below");
    let newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    const diffTime = Math.abs(newDate - date);
    deltaDays = -Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    date = newDate;
  }
  return [date, deltaDays];
}
export function getMonthRange(numOfMonths, date) {
  let sd = date;
  let dateToAddOneMonth = new Date(date);
  let dateRange = [sd];
  let deltaDays = 0;
  for (let i = 1; i < numOfMonths; i++) {
    [dateToAddOneMonth, deltaDays] = addOneMonth(dateToAddOneMonth, deltaDays);
    dateRange.push(new Date(dateToAddOneMonth));
  }
  return dateRange;
}
export function formatRowsToLocaleString(rows) {
  rows = rows.map((obj) => {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "number") {
        newObj[key] = parseToLocaleString(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  });
  return rows;
}
export function parseToLocaleString(value) {
  return value?.toLocaleString("en-US", {
    timeZone: "America/Argentina/Buenos_Aires",
  });
}
