<template>
  <v-navigation-drawer
    disable-resize-watcher
    :value="sideBarDrawer"
    app
    class="p-4"
  >
    <v-list>
      <v-list-item
        class="d-flex flex-row"
        v-for="route in routes"
        :key="route.path"
        @click="toggleSideBarDrawer"
      >
        <div class="d-flex">
          <router-link
            :to="route.path"
            style="color: black !important"
            class="d-flex text--black text-decoration-none"
          >
            <v-list-item-icon>
              <v-icon> {{ route.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <div class="text--black text-decoration-none">
                  {{ route.text }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SideBar",
  computed: {
    ...mapGetters(["sideBarDrawer"]),
  },
  methods: {
    ...mapActions(["toggleSideBarDrawer"]),
  },
  data() {
    return {
      drawer: true,
      routes: [
        {
          path: "/abstract-campaign",
          text: "Campaigns",
          icon: "mdi-atom",
        },
        // {
        //   path: "/engagement",
        //   text: "Engagement",
        //   icon: "mdi-abacus",
        // },

        {
          path: "/CFManager",
          text: "Slice Explorer",
          icon: "mdi-poll",
        },
        {
          path: "/cost-items",
          text: "Cost Items",
          icon: "mdi-alarm-panel",
        },
      ],
    };
  },
};
</script>
