<template>
  <div>
    <v-app>
      <TopBar />
      <SideBar />
      <v-main>
        <!-- ROUTER VIEW -->
        <router-view :key="$route.fullPath" />
        <!-- ROUTER VIEW -->

        <SnackBackHandler
          v-for="(notification, index) in notificationsQueue"
          :key="notification.id"
          :notification="notification"
          :notificationId="notification.id"
          :notificationIndex="index"
          :pastNotificationInfoLength="pastNotificationInfoLength(index)"
        />
      </v-main>
      <!-- <v-app-bar app bottom dense short max-height="35px">
        <div class="d-flex align-center">
          <div class="mb-4">Updated at {{ new Date() }}</div>
        </div>
      </v-app-bar> -->
      <v-overlay :value="isPageLoading" :opacity="0">
        <v-progress-circular
          class="mt-4 ml-4"
          :width="10"
          size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <v-navigation-drawer
        v-model="bottomDrawer"
        absolute
        temporary
        right
        :mobile-breakpoint="0"
      >
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title>
                <v-btn block @click="() => (this.$vuetify.theme.dark = true)">
                  Dark Mode
                </v-btn>
                <v-btn
                  class="mt-2"
                  block
                  @click="() => (this.$vuetify.theme.dark = false)"
                >
                  Light Mode
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-app>
  </div>
</template>

<style>
body {
  font-weight: 400;
}
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 6em;
  z-index: 2;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  opacity: 0.9;
  margin-top: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  width: 10px;
}
::-webkit-scrollbar-track:horizontal {
  border-radius: 5px;
  width: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 0px;
  width: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #25026a;
}
</style>
<script>
import { mapGetters } from "vuex";
import TopBar from "./components/Global/TopBar.vue";
import SideBar from "./components/Global/SideBar.vue";
import SnackBackHandler from "./views/SnackBarError/SnackBackHandler.vue";
import { getDecodedTokenFromAuthHeader } from "./managrx/AutAndAuthServices";

export default {
  name: "App",
  components: { TopBar, SideBar, SnackBackHandler },
  mounted() {
    //
    //
    this.$vuetify.theme.dark = false;
  },
  updated(){
    let decodedToken = getDecodedTokenFromAuthHeader();
    if (decodedToken?.token) {
      this.$store.dispatch("setUserTokenInfo", decodedToken?.token);
    }
  },
  methods: {
    pastNotificationInfoLength(index) {
      if (index > 0) {
        let pastIndex = index - 1;
        return this.notificationsQueue?.[pastIndex]?.info
          ? this.notificationsQueue?.[pastIndex]?.info?.length
          : 1;
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapGetters(["isPageLoading", "notificationsQueue"]),
  },
  data: () => ({
    //
    group: false,
    bottomDrawer: false,
  }),
};
</script>
