<template>
  <v-row>
    <v-col v-if="isSuccess"
      ><Success
        :index="notificationIndex"
        :id="notificationId"
        :pastNotificationInfoLength="pastNotificationInfoLength"
        :hasInfo="hasInfo"
        :info="info"
        :text="notificationText"
        @closeToast="closeToast"
    /></v-col>
    <v-col v-if="isAlert"
      ><Alert
        :id="notificationId"
        :index="notificationIndex"
        :pastNotificationInfoLength="pastNotificationInfoLength"
        :hasInfo="hasInfo"
        :info="info"
        @closeToast="closeToast"
        :text="notificationText"
    /></v-col>
    <v-col v-if="isError"
      ><Error
        :id="notificationId"
        :index="notificationIndex"
        :pastNotificationInfoLength="pastNotificationInfoLength"
        :status="status"
        :hasInfo="hasInfo"
        :info="info"
        @closeToast="closeToast"
        :text="notificationText"
    /></v-col>
  </v-row>
</template>

<script>
import Error from "../../components/SnackBarsErrors/Error.vue";
import Alert from "../../components/SnackBarsErrors/Alert.vue";
import Success from "../../components/SnackBarsErrors/Success.vue";
export default {
  name: "SnackBackHandler",
  components: { Error, Success, Alert },
  props: {
    notification: Object,
    notificationId: Number,
    notificationIndex: Number,
    pastNotificationInfoLength: Number,
  },
  methods: {
    closeToast(id) {
      this.$store.dispatch("unSetNotification", id);
    },
  },
  computed: {
    hasInfo() {
      let hasInfo = this.notification.info ? true : false;
      return hasInfo;
    },
    info() {
      if (this.hasInfo) {
        return this.notification.info;
      } else {
        return [];
      }
    },
    notificationText() {
      return this.notification.text;
    },
    status() {
      return this.notification.status;
    },
    type() {
      return this.notification.type;
    },
    isSuccess() {
      if (this.type == "success") {
        return true;
      }
      return false;
    },
    isError() {
      if (this.type == "error") {
        return true;
      }
      return false;
    },
    isAlert() {
      if (this.type == "alert") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style></style>
